import { Text, Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { AuthModal } from "src/molecules/AuthModal";
import { AuthType } from "src/molecules/AuthModal/AuthModal";
import {
  ButtonsWrapper,
  SubHeading,
  StyledButton,
  UnlockIcon,
  Wrapper,
} from "./AuthModule.styled";
import { SEGMENT_IN_VIEW_THRESHOLD } from "src/segment";
import { useSegment } from "src/hooks/useSegment";
import { PLPType } from "src/types/PLPType";
import { HomePageType } from "src/types/HomePageType.d";
import NoSSR from "src/atoms/NoSSR";
import classNames from "classnames";

const AuthModule = ({
  trackingData,
  noMarging,
}: {
  trackingData?: {
    pageType?: PLPType | HomePageType;
    categorySlug?: string;
    pageName?: string;
    brand?: string;
    shopType?: string;
  };
  noMarging?: boolean;
}): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthType>(AuthType.SIGN_IN);
  const {
    segmentViewedAuthBanner,
    segmentAuthBannerClosed,
    segmentAuthBannerSelect,
  } = useSegment();

  const [ref] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        void segmentViewedAuthBanner(trackingData?.pageName);
      }
    },
  });

  return (
    <>
      <Wrapper ref={ref} className={classNames({ ["no-margin"]: noMarging })}>
        <Box>
          <Heading
            fontSize={"24px"}
            fontFamily="CharisSILW"
            lineHeight={"32px"}
            pb={0}
            color="White"
          >
            <Trans>Log in to access ALL fashion deals</Trans>
          </Heading>
          <SubHeading>
            <NoSSR>
              <UnlockIcon color="White" role="presentation" />
            </NoSSR>
            <Text
              variant="body.source.regular18"
              color="White"
              ml={1}
              as="span"
              letterSpacing={0.1}
            >
              <Trans>
                Unlock the best fashion deals from your favourite brands
              </Trans>
            </Text>
          </SubHeading>
        </Box>
        <ButtonsWrapper>
          <StyledButton
            variant="secondary"
            colorScheme="light"
            onClick={() => {
              setAuthType(AuthType.SIGN_UP);
              setOpen(true);
              void segmentAuthBannerSelect({
                type: "sign_up",
                page_type: trackingData?.pageType,
                shop_type: trackingData?.shopType,
                category_slug: trackingData?.categorySlug,
                ...(trackingData?.brand
                  ? { brand: trackingData?.brand }
                  : { category: trackingData?.pageName }),
              });
            }}
          >
            <Trans>Register</Trans>
          </StyledButton>
          <StyledButton
            variant="primary"
            colorScheme="light"
            onClick={() => {
              setAuthType(AuthType.SIGN_IN);
              setOpen(true);
              void segmentAuthBannerSelect({
                type: "sign_in",
                page_type: trackingData?.pageType,
                category_slug: trackingData?.categorySlug,
                shop_type: trackingData?.shopType,
                ...(trackingData?.brand
                  ? { brand: trackingData?.brand }
                  : { category: trackingData?.pageName }),
              });
            }}
          >
            <Trans>Log in</Trans>
          </StyledButton>
        </ButtonsWrapper>
      </Wrapper>
      {open && (
        <AuthModal
          open={open}
          authType={authType}
          onAuthTypeChange={(newAuthType) => setAuthType(newAuthType)}
          origin="auth-banner"
          onClose={() => {
            void segmentAuthBannerClosed({
              popup_type: authType === AuthType.SIGN_IN ? "sign_in" : "sign_up",
              page_type: trackingData?.pageType,
              category_slug: trackingData?.categorySlug,
              shop_type: trackingData?.shopType,
              ...(trackingData?.brand
                ? { brand: trackingData?.brand }
                : { category: trackingData?.pageName }),
            });
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AuthModule;
